<template>
	<div class="main-contents">
        <form name="form_auth" method="post" :action="input.gwUrl">
        	<input type="hidden" name="ordr_idxx" class="frminput" v-model="input.ordr_idxx" size="40" maxlength="40"/>
        	<input type="hidden" name="user_name" value="" size="20" maxlength="20" class="frminput" />
        	<input type="hidden" name="year" value="" />
        	<input type="hidden" name="month" value="" />
        	<input type="hidden" name="day" value="" />
        	<input type="hidden" name="sex_code" value="" />
        	<input type="hidden" name="local_code" value="" />
        	<!-- 요청종류 -->
            <input type="hidden" name="req_tx"       value="cert"/>
            <!-- 요청구분 -->
            <input type="hidden" name="cert_method"  value="01"/>
            <!-- 웹사이트아이디 : ../cfg/cert_conf.jsp 파일에서 설정해주세요 -->
            <input type="hidden" name="web_siteid"   :value="input.webSiteId"/> 
            <!-- 노출 통신사 default 처리시 아래의 주석을 해제하고 사용하십시요 
                 SKT : SKT , KT : KTF , LGU+ : LGT
            <input type="hidden" name="fix_commid"      value="KTF"/>
            -->
            <!-- 사이트코드 : ../cfg/cert_conf.jsp 파일에서 설정해주세요 -->
            <input type="hidden" name="site_cd"      :value="input.siteCd" />               
            <!-- Ret_URL : ../cfg/cert_conf.jsp 파일에서 설정해주세요 -->
            <input type="hidden" name="Ret_URL"      :value="input.retUrl" />
            <!-- cert_otp_use 필수 ( 메뉴얼 참고)
                 Y : 실명 확인 + OTP 점유 확인 , N : 실명 확인 only
            -->
            <input type="hidden" name="cert_otp_use" value="Y"/>
            <!-- cert_enc_use 필수 (고정값 : 메뉴얼 참고) -->
            <input type="hidden" name="cert_enc_use" value="Y"/>
            <!-- 리턴 암호화 고도화 -->
            <input type="hidden" name="cert_enc_use_ext" value="Y"/>

            <input type="hidden" name="res_cd"       value=""/>
            <input type="hidden" name="res_msg"      value=""/>

            <!-- up_hash 검증 을 위한 필드 -->
            <input type="hidden" name="veri_up_hash" value=""/>

            <!-- 본인확인 input 비활성화 -->
            <input type="hidden" name="cert_able_yn" value=""/>

            <!-- web_siteid 검증 을 위한 필드 -->
            <input type="hidden" name="web_siteid_hashYN" value=""/>

            <!-- 가맹점 사용 필드 (인증완료시 리턴)-->
            <input type="hidden" name="param_opt_1"  value="opt1"/> 
            <input type="hidden" name="param_opt_2"  value="opt2"/> 
            <input type="hidden" name="param_opt_3"  value="opt3"/> 

			<input type="hidden" name="up_hash"  :value="input.up_hash"/> 
			<input type="hidden" name="kcp_cert_lib_ver"  :value="input.kcp_cert_lib_ver"/> 

        </form>

	</div>
</template>

<script>
export default {
	data() {
		return {
			input : {
				ordr_idxx : '',
				siteCd : '',
				webSiteId : '',
				retUrl : '',
				gwUrl : '',
				
				up_hash : '',
				kcp_cert_lib_ver : '',
			}
		}
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');
		this.getKcpcertConfig();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getKcpcertConfig() {
			this.$.httpPost('/api/mem/kcpcert/getKcpcertConfig', this.input)
				.then(res => {
					// console.log("================ getKcpcertConfig")
					// console.log(res.data);	
					
					this.input.ordr_idxx 		= res.data.ordr_idxx;
					this.input.siteCd 			= res.data.siteCd;
					this.input.webSiteId 		= res.data.webSiteId;
					this.input.retUrl 			= location.origin + res.data.retUrl;
					this.input.gwUrl  			= res.data.gwUrl;

					this.input.up_hash 			= res.data.up_hash;
					this.input.kcp_cert_lib_ver = res.data.kcp_cert_lib_ver;

					window.opener.setKcpVeriUpHash(res.data.up_hash);

					setTimeout(this.formSubmit, 1);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},
		
		formSubmit() {
			document.form_auth.submit();
		},
	},
}
</script>